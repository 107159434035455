.map-container {
  height: 650px;
  width: 100%;
}

.cluster {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #1cd4b0;
  color: white;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
  margin-top: -20px;
  margin-left: -20px;
}

.leaflet-popup-content .popup_buttons {
  margin-top: 10px !important;
  width: 100%;
  text-align: center;
}

.leaflet-popup-content .popup_buttons a {
  color: #1cd4b0;
  text-decoration: none;
}

.leaflet-bar.agenda {
  font-size: 16px;
}

.leaflet-sidebar.collapsed {
  width: 42px;
}

.leaflet-sidebar-tabs > li.active, .leaflet-sidebar-tabs > ul > li.active {
  color: #000 !important;
  background-color: #FFF !important;
}

.leaflet-sidebar-header {
  background-color: #1A1A1A !important;
  margin-bottom: 20px !important;
}

.leaflet-sidebar-header.bg-mint {
  background-color: #1cd4b0 !important;
}

.leaflet-sidebar {
  z-index: 1000;
}
.leaflet-top, .leaflet-bottom {
  z-index: 999;
}

#search input {
  width: 94%;
  font-size: 12px;
  height: 28px;
  border: 1px solid grey;
  background: transparent;
}

.leaflet-sidebar-content .btn-link,
.leaflet-sidebar-content .btn-link:hover {
  font-weight: 400;
  color: #174c8d;
  text-decoration: none;
}

.leaflet-sidebar-content .card-header {
  padding: 0;
}

.leaflet-sidebar-content .filter-item {
  border-bottom: 0;
}

.leaflet-sidebar-content .card {
  border-radius: 0;
  margin-bottom: -2px;

}

.collapse-checkbox {
  padding-right: 15px;
}

.collapse-checkbox input {
  width: 20px;
  height: 20px;
}

.subCatRow {
  padding: 7px 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#search-results {
  height: 88%;
  overflow: auto;
}

#search-results p {
  cursor: pointer;
  color: #989898;
}

.cat-disabled {
  opacity: 0.5;
}

.category-filter {
  cursor: pointer;

  &.active {
    color: #b6b6b6;
  }
}

//max width
#sidebar, .leaflet-sidebar-content, .leaflet-pane, .leaflet-sidebar-pane {
  max-width: 372px;
}

@media (min-width: 1200px) {
  .leaflet-sidebar-pane {
    min-width: 200px !important;
  }
}
